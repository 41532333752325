.masterclass {
    &-page {
        color: #252525;
        &__header {
            font-size: 20px;
            letter-spacing: 0.1em;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
            padding: 0 15px;
            display: grid;
            grid-template-columns: 15% 70% 15%;
            &-title {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
