.about {
    padding: 0 15px;
    margin-bottom: 7px;
    &:last-child {
        margin-bottom: 0;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 20%;
            height: 3px;
            border-radius: 10px;
            background-color: #e6e6e6;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__icon {
        line-height: 0px;
    }
    &__name {
        margin: 0;
        padding: 15px 5px;
    }
}

.chapter {
    cursor: pointer;
    &__subtitle {
        background: #bfbfbf;
        padding: 5px 0;
        border-top: 2px solid #e8e8e8;
        border-bottom: 2px solid #e8e8e8;
    }
    &__list {
        padding: 10px 0;
        margin: 0;
        list-style: none;
    }
}
