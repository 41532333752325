.app {
    text-align: center;
    padding-top: 10px;
}

.google_oauth_button {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: auto;
    width: 100%;
}

.backButton {
    cursor: pointer;
}

.closeButton {
    cursor: pointer;
}
