.strip {
    padding: 0 5px;
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
    &__link {
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #252525;
        font-size: 18px;
        letter-spacing: 0.2em;
        font-weight: 600;
        text-decoration: none;
    }
    &__title {
        padding: 40px 0;
    }
    &__text {
        color: #fff;
    }
    &__wrap {
        border-radius: 10px;
        overflow: hidden;
    }
}

.listItem {
    display: flex;
    flex-direction: row;
    justify-items: center;
    border: 1px dotted gray;
    overflow: scroll;
    overflow-x: hidden;
    word-break: break-all;
    padding-bottom: 2px;
}

.listItem a {
    width: 100%;
}

.thumbnail img {
    /* border: 1px gray dotted; */
    height: 100px;
    width: 100px;
}

.namePlaceholder {
    align-self: center;
    width: 100%;
}

.name {
    align-self: center;
}
