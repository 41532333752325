.video-resource {
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    &__player {
        max-width: 100%;
    }
}
