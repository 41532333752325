* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.42;
    overflow-x: hidden;
    background-color: #f5f5f5;
    @media (max-width: 360px) {
        overflow: auto;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

img {
    max-width: 100%;
    display: block;
}

.container {
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    max-width: 991px;
    @media (max-width: 360px) {
        min-width: 360px;
    }
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}

.subtitle {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    border: 0;
    padding: 5px 10px;
    justify-content: center;
    border-radius: 6px;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #252525;
    outline: none;
    width: 100%;
    cursor: pointer;
    line-height: 1.42;
    &_type {
        &_common {
            background: #144483;
        }
    }
    &_width_auto {
        width: auto;
    }
}

.logo {
    background: #fff;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
    &__link {
        display: block;
        padding: 20px;
        max-width: 110px;
        @media (max-width: 575px) {
            padding: 3vw;
        }
    }
    @media (max-width: 575px) {
        min-width: 20%;
    }
}
