.masterclass {
    &__head {
        margin-bottom: 15px;
    }
    &__title {
        position: relative;
        padding-bottom: 15px;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 10%;
            left: 50%;
            transform: translateX(-50%);
            height: 5px;
            border-radius: 10px;
            background-color: #e6e6e6;
        }
    }
    &__thumbnail {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }
    &__pic {
        border-radius: 30px;
        overflow: hidden;
        width: clamp(180px, 40vw, 360px);
    }
    &__description {
        margin-bottom: 10px;
        padding: 0 10px 15px;
    }
    &__text {
        margin: 0;
    }
}
