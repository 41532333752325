.footer {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 0;
    // margin-top: auto;
    &__confidential {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__privacy {
        color: #000;
        text-decoration: none;
        margin-bottom: 3px;
        padding-bottom: 10px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
            height: 2px;
            background-color: #000;
            border-radius: 5px;
        }
    }
    &__terms {
        text-decoration: none;
        color: #000;
    }
}
