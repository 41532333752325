.header {
    padding: 15px 0;
    margin-bottom: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 1px 2px 7px 0px rgba(50, 50, 50, 0.66);
    -moz-box-shadow: 1px 2px 7px 0px rgba(50, 50, 50, 0.66);
    box-shadow: 1px 2px 7px 0px rgba(50, 50, 50, 0.66);
    &__title {
        color: #252525;
        font-weight: bold;
        font-size: 18px;
        padding: 0 5px;
        margin: 0;
    }
    &__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 20px;
        margin-bottom: 15px;
        &_admin {
            flex-grow: 1;
            width: 100%;
            justify-content: center;
        }
    }
    &__btn {
        display: flex;
    }
    &__controls {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__profile {
        &_button {
        }
    }
}

/// Layout for sign out button

:root {
    --amplify-primary-color: gray;
    --amplify-primary-tint: gray;
    --amplify-primary-shade: gray;
}

amplify-sign-out button {
    background-color: gray;
}
